import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Decoration = makeShortcode("Decoration");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const BlockProjects = makeShortcode("BlockProjects");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnimateIntroBanner id="banner" className="background_logo dark" background={<><RowLines mdxType="RowLines" /><Decoration from="/sector-industrial/banner.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroBanner">  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Industrial Sites & Factories
    </Heading>
    <LearnMore elementId="projects_list" color="white" mdxType="LearnMore" />
  </Block>
  <Block className="content_block" mdxType="Block">
    <Paragraph mdxType="Paragraph">Effectiveness and business continuity are the key for any successful production facility. Our preventive and predictive maintenance expertise allows our clients to minimize unnecessary disruptions, downtime, and expenses, and keep their business running smoothly over time.
    </Paragraph>
  </Block>  
    </AnimateIntroBanner>
    <Row id="projects_list" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <BlockProjects sector="industrial-sites-and-factories" flipped={true} mdxType="BlockProjects" />
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      